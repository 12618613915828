import React from 'react';

// intersection observer 
import { InView, useInView } from 'react-intersection-observer';
// motion 
import {motion} from 'framer-motion'; 
// variant 
import { fadeIn } from '../variants';
//  link 
import {Link} from 'react-scroll'; 


 


const About = () => {
  const [ref, inView]= useInView({
    threshold: 0.5, 
  }); 
  return (
    <section className='section' id='about' ref={ref}>
      <div className= "container mx-auto">
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div 
          variants= {fadeIn('right', 0.3)}
          initial = "hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className= 'flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'></motion.div>
           {/* text */}
           <motion.div 
           variants= {fadeIn('left', 0.3)}
           initial = "hidden"
           whileInView={"show"}
           viewport={{once: false, amount: 0.3}}
           className='flex-1'>
            <h2 className= 'h2 text-accent'> About me. </h2>
            <h3 className='h3 mb-4'>I'm a Jr. Web Developer</h3>
            <p className= 'mb-4'> I understand the importance of creating a design that is unique and easily accessible for all users. Web Development allows me to showcase my design and coding skills while using the lastest practices of UX/UI practices. Besides my passion for coding, I enjoy traveling, spending time with family and friends, and watching movies or eating really spicy foods! </p>

        
          <div className='flex gap-x-8 items-center'>
          <Link to='contact'><button className='btn btn-lg'>Contact me</button> </Link>
         
            <a href = '#work' className='text-gradient btn-link'>
                My Portfolio
            </a>
         
          </div>
        </motion.div>
      </div>
    </div>
    </section>
  );
};

export default About;

import React from 'react';

// images 

import Image from '../assets/avatar.png'; 

// icons 
import { FaGithub, FaYoutube, FaDribbble, FaLinkedin} from 'react-icons/fa'

// type animation 
import { TypeAnimation } from 'react-type-animation';

// Framer Motion 
import {motion} from 'framer-motion'; 

//  import motion 
import {fadeIn} from '../variants'

//  link 
import {Link} from 'react-scroll'; 

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-[78vh] flex items-center' id='home'>
       <div className="container mx-auto">
          <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
            {/* text  */}
            <div className='flex-1 text-center font-secondary lg:text-left'> 
              <motion.h1 
                variants={fadeIn('up', 0.3)}  
                initial = "hidden" 
                whileInView={'show'} 
                viewport={{once: false, amount: 0.7}}
                className = 'text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
                Alyssa <span>Herrera</span>
              </motion.h1>
              <motion.div 
                variants={fadeIn('up', 0.4)}  
                initial = "hidden" 
                whileInView={'show'} 
                viewport={{once: false, amount: 0.7}}
              className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className=' text-white first-letter:mr-4'>I am a </span>
              <TypeAnimation sequence = {[
                'Developer', 
                2000, 
                'Designer', 
                2000,
              ]} 
              speed={50}
              className= 'text-accent'
              wrapper='span'
              repeat={Infinity}
                />
              </motion.div>
              <motion.p 
                variants={fadeIn('up', 0.5)}  
                initial = "hidden" 
                whileInView={'show'} 
                viewport={{once: false, amount: 0.7}}
              className= 'mb-8 max-w-lg mx-auto lg:mx-0'
              >Welcome to my Web Developer portfolio! Here you will find a few of my projects, Github & LinkedIn profile, downloadable resume, and a contact form for inquiries.
              </motion.p>
              <motion.div 
                variants={fadeIn('up', 0.6)}  
                initial = "hidden" 
                whileInView={'show'} 
                viewport={{once: false, amount: 0.7}}
              className = 'flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
               <Link to='contact'> <button className= 'btn btn-lg'>Contact Me </button></Link>
                <a href = '#work' className='text-gradient btn-link'>My Portfolio</a>
              </motion.div>
              {/* socials */}
              <motion.div 
                variants={fadeIn('up', 0.7)}  
                initial = "hidden" 
                whileInView={'show'} 
                viewport={{once: false, amount: 0.7}}
              className= 'flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
                <a href = 'https://github.com/alyssagabrie'>
                  <FaGithub />
                </a>
                <a href = 'https://www.linkedin.com/in/alyssa-g-herrera/'>
                  <FaLinkedin />
                </a>
              </motion.div>
            </div> 
             {/* image  */}
             <motion.div 
               variants={fadeIn('down', 0.5)}  
               initial = "hidden" 
               whileInView={'show'} 
              className= 'hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] '>
              <img src={Image} alt= '' />
             </motion.div>
          </div>

       </div>
    </section>
  
    )
};

export default Banner;
